<template>
  <section>
    <section v-if="batch" class="container-header">
      <div class="text-container mb-1">
        <h3 class="title">{{ title }}</h3>

        <div class="btn-title-container">
          <!--<b-button variant="outline-primary" class="btn-edit" @click="openInfoSidebar">
            <Edit class="icon edit" />
          </b-button>-->
          
          <b-dropdown 
            v-if="batch.status !== SusBatch.BATCH_STATUS_FINISHED"
            variant="primary"
            text="Gerenciar lote" 
          >
            <b-dropdown-item v-for="(status, index) in filteredStatuses(batch.status)" :key="index" variant="link" @click="handleInvoiceSituation(status.label)">
              {{ status.label }}
            </b-dropdown-item>
          </b-dropdown>

          <span 
            class="px-4 py-2 ml-1" 
            :class="{
              'red': batch.status === SusBatch.BATCH_STATUS_GLOSSED, 
              'green': batch.status === SusBatch.BATCH_STATUS_PAYED || batch.status == SusBatch.BATCH_STATUS_FINISHED,
              'blue': batch.status === SusBatch.BATCH_STATUS_SENT   || batch.status === SusBatch.BATCH_STATUS_RESENT          
            }"
          >
            Situação do lote: {{ batch.status }}
          </span>

          <b-button
            v-if="batch.status === SusBatch.BATCH_STATUS_PAYED || batch.status === SusBatch.BATCH_STATUS_GLOSSED"
            variant="success"
            @click="$emit('finishBatch', batch)"
          >
            {{ !batch.bill ? 'Finalizar lote' : 'Inserir pagamento' }}
          </b-button>
        </div>
      </div>
      <div class="subtitle mt-3">
        <p><span>Tipo de lote:</span> {{ getTypes(batch?.type) }}</p>
        <p><span>Lote Nº:</span> {{ batch?.number }}</p>        
        <p><span>Versão:</span> 1 </p>
        <p><span>Envio:</span> {{ batch?.send_date ? this.moment(batch.send_date).format('DD/MM/YYYY') : '-' }}</p>
        <p><span>Responsável:</span>{{ batch.responsible?.name}}</p>
      </div>
    </section>
    <hr />
  </section>
</template>

<script>
import { userHasPermission } from '@/utils/localStorageManager'
import { getTypes } from '@/modules/sus/manageGuides/utils/guideHelper'
import { SusBatchStatusOptions, SusBatch } from '@/modules/sus/manageGuides/utils/statuses'

export default {
  name: 'BatchHeaderContainer',
  components: {
    // Edit: () => import('@/assets/icons/edit.svg')
  },
  props: {
    title: String,
    batch: Object,
    openManageGuides: {
      type: Function,
      required: true
    },
    handleInvoiceSituation: {
      type: Function,
      required: true
    },
  },
  data() {
    return {
      SusBatch,
      SusBatchStatusOptions,
      selection: false
    }
  },
  methods: {
    getTypes,
    userHasPermission,
    filteredStatuses(status){
      return this.SusBatchStatusOptions.filter(el => !el.hidden && el._display && el._display.includes(status))
    },

    openInfoSidebar() {
      this.$emit('openInfo', this.batch.id)
    }
  },
}
</script>

<style lang="scss" scoped>
.container-header {
  .text-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title {
    color: var(--dark-blue);
    letter-spacing: -0.01em;
    font-weight: 700;
    font-size: 18px;
  }

  .subtitle {
    display: flex;
    flex-direction: row;

    p {
      font-weight: 700;
      font-size: 0.8rem;
      margin-right: 20px;

      @media (max-width: 1368px) {
        font-size: 14px;
      }

      span {
        color: var(--blue-600);
        margin-right: 5px;
      }
    }
  }
}

.delete {
  padding: 4px;
  width: 56px;
  height: 36px;
  border: 2px solid var(--states-red-soft);
  border-radius: 8px;
  margin-top: 1px;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn-title-container {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;

  div {
    margin-left: 5px;
  }

  .btn-edit {
    padding: 5px 8px;

    svg {
      width: 24px;
      height: 24px;
      stroke: var(--blue-600);
    }
  }

  .btn-upload {
    padding: 5px 8px;

    svg {
      height: 24px;
      width: 24px;
      overflow: inherit;
      fill: var(--neutral-000);
      stroke: var(--blue-500);
    }
  }
}

hr {
  width: 100%;
  color: var(--neutral-200);
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 1%;

  &.trash {
    fill: none;
    stroke: var(--states-red-soft);
  }
}

.red {
  background-color: #FEE4E2; 
  color: #D82828;
}

.green {
  background-color: #DBFFF0;
  color: #097C53;
}

.blue {
  background-color: #E7ECFE;
  color: #305BF2;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

/* Estilo adicional para bordas arredondadas */
span {
  border-radius: 0.25rem;
}
</style>
